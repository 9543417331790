.blog .post {
  margin-bottom: 50px;
}


.post {

}

.comment-list {

  .comment {
    padding: 20px;

    .comment-meta {
      margin-bottom: 20px;

      .avatar {
        border-radius: 100%;
        float: left;
        margin-right: 20px;
      }
    }
  }
}

/* Small devices (tablets, 768px and up) */
@media (min-width: $screen-sm-min) { }

/* Medium devices (desktops, 992px and up) */
@media (min-width: $screen-md-min) { }

/* Large devices (large desktops, 1200px and up) */
@media (min-width: $screen-lg-min) { }