body {
  font-family: 'Open Sans', sans-serif;
  font-size: 15px;
  color: #424242;

  &.nav-mobile-open {
    overflow: hidden;
  }
}

img {
  max-width: 100%;
  height: auto;
}

/* Small devices (tablets, 768px and up) */
@media (min-width: $screen-sm-min) { }

/* Medium devices (desktops, 992px and up) */
@media (min-width: $screen-md-min) { }

/* Large devices (large desktops, 1200px and up) */
@media (min-width: $screen-lg-min) { }