.footer {
  background: #333;
  padding-top: 60px;
  padding-bottom: 60px;
  font-size: 14px;

  .widget {
    color: #fff;
    
    .widget__title {
      font-size: 1.1em;
      color: #fff;
      margin: 0 0 10px;
      padding: 0;
    }
  }
}
.sub-footer {
  background: #222;
  padding-top: 10px;
  padding-bottom: 10px;
  color: #fff;
  font-size: 0.9em;
}

/* Small devices (tablets, 768px and up) */
@media (min-width: $screen-sm-min) { }

/* Medium devices (desktops, 992px and up) */
@media (min-width: $screen-md-min) { }

/* Large devices (large desktops, 1200px and up) */
@media (min-width: $screen-lg-min) { }