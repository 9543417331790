// Grid system
.main {
  @include make-sm-column($main-sm-columns);
  .sidebar-primary & {
    @include make-sm-column($main-sm-columns - $sidebar-sm-columns);
  }
}

.wrap {
  padding-top: 15px;
  padding-bottom: 15px;
}

.home,
.page-template-template-elementor {
  .wrap {
    padding-top: 0;
    padding-bottom: 0;
  }
  .main {
    padding: 0;
    float: none;
    width: auto;
  }
}

.sidebar {
  @include make-sm-column($sidebar-sm-columns);
}

/* Small devices (tablets, 768px and up) */
@media (min-width: $screen-sm-min) {
}

/* Medium devices (desktops, 992px and up) */
@media (min-width: $screen-md-min) {
}

/* Large devices (large desktops, 1200px and up) */
@media (min-width: $screen-lg-min) {
  .wrap {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}