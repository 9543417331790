.banner {
  background: #242424;
  padding-top: 23px;
  padding-bottom: 23px;
  position: relative;
  z-index: 10;
  text-align: center;

  .brand {
    font-size: 2em;
    color: #fff;
  }
  .custom-logo-link .custom-logo {
    width: 150px;
    height: auto;
  }
}
.home,
.page-template-template-elementor {
  .banner {
    background: transparent;
    position: absolute;
    width: 100%;
  }
}


.nav-primary {
  display: none;
  float: right;
  margin-top: 0px;

  .nav {
    list-style: none outside;
    margin: 0;
    padding: 0;

    li {
      float: left;
      position: relative;

      a {
        color: #fff;
      }
      &.menu-item-has-children {
        a {
          padding-right: 28px;
        }
        > a::after {
          font-family: 'FontAwesome';
          content: "\f078";
          font-size: 10px;
          display: block;
          width: 20px;
          height: 20px;
          position: absolute;
          right: 0;
          top: 30%;
        }

        &:hover .sub-menu {
          display: block;
        }
      }
    }
  }
  .sub-menu {
    display: none;
    position: absolute;
    background: #fff;
    color: #333;
    list-style: none outside;
    width: 200px;
    margin: 0;
    padding: 10px 0;

    li {
      float: none;

      a {
        display: block;
        color: #666;
        padding: 10px 20px;
      }
    }
  }
}

.mobile-hamburger {
  position: absolute;
  top: 0;
  right: 0px;
  z-index: 20;
  width: 30px;
  height: 24px;
  margin: 35px 30px 0 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .5s ease-in-out;
  -moz-transition: .5s ease-in-out;
  -o-transition: .5s ease-in-out;
  transition: .5s ease-in-out;
  cursor: pointer;

  span {
    display: block;
    position: absolute;
    height: 3px;
    width: 100%;
    background: #ffffff;
    border-radius: 0px;
    opacity: 1;
    left: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .25s ease-in-out;
    -moz-transition: .25s ease-in-out;
    -o-transition: .25s ease-in-out;
    transition: .25s ease-in-out;
  }

  span:nth-child(1) {
    top: 0px;
  }
  span:nth-child(2), span:nth-child(3) {
    top: 10px;
  }
  span:nth-child(4) {
    top: 20px;
  }
  &.open span:nth-child(1) {
    top: 18px;
    width: 0%;
    left: 50%;
  }
  &.open span:nth-child(2) {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
  }
  &.open span:nth-child(3) {
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }
  &.open span:nth-child(4) {
    top: 18px;
    width: 0%;
    left: 50%;
  }
}

.nav-mobile {
  display: none;
  background: rgba(0, 0, 0, 0.9);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  padding: 100px 50px 50px;
  color: #fff;
  z-index: 10;
  font-size: 1.2em;
  text-align: center;

  ul {
    list-style: none outside;
    margin: 0;
    padding: 0;
  }

  a {
    color: #fff;
  }

  &.open {
  }
}

/* Small devices (tablets, 768px and up) */
@media (min-width: $screen-sm-min) {

}

/* Medium devices (desktops, 992px and up) */
@media (min-width: $screen-md-min) {
  .banner {
    text-align: left;
    padding-top: 33px;
    padding-bottom: 33px;
  }
}

/* Large devices (large desktops, 1200px and up) */
@media (min-width: $screen-lg-min) {
  .nav-primary { display: block; }
  .mobile-hamburger { display: none; }

}