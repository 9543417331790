.sidebar {
  padding-top: 40px;

  .widget {
    margin:0 0 50px;
    padding: 0;
    font-size: 14px;

    .widget__title {
      font-size: 1.2em;
      font-weight: bold;
      margin: 0 0 10px;
      color: #565656;
    }

    ul {
      margin: 0;
      padding-left: 0px;

      li {
        background: url("../images/puce.svg") no-repeat;
        list-style: none outside;
        background-position: 0px 7px;
        padding-left: 15px;
      }
    }
  }

  .widget_search {
    position: relative;

    label {
      width: 100%;
    }

    .search-field {
      box-shadow: none;
      border-radius: 0;
      border: 1px solid #C2C2C2;
      width: 100%;
      margin: 0;
      padding: 26px 12px;
    }
    .search-submit {
      position: absolute;
      right: 8px;
      top: 7px;
      border: none;
      background: none;
      padding: 10px 15px;
      border-radius: 0;
      box-shadow: none;
      outline: none;

      &:active:focus {
        background: none;
      }
    }
  }
}

/* Small devices (tablets, 768px and up) */
@media (min-width: $screen-sm-min) { }

/* Medium devices (desktops, 992px and up) */
@media (min-width: $screen-md-min) {
  .sidebar {
    padding-top: 0;
  }
}

/* Large devices (large desktops, 1200px and up) */
@media (min-width: $screen-lg-min) {
  .sidebar {
    padding-left: 30px;
  }
}