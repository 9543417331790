.elementor-editor-active {
  .banner {
    pointer-events: none;
  }
}
.elementor-element {
  &.elementor-button-warning .elementor-button {
    background-color: $yellow;
  }
}

// define this class in the button component
.btn-ghost .elementor-button {
  background: transparent;
  border: 1px solid #fff;
}

// header logo inclusion in text
.header-jumbotron .mad {
  content: " ";
  display: inline-block;
  background: url('../images/logo-mad-mg.png') no-repeat;
  background-size: contain;
  text-indent: -999px;
  overflow: hidden;
  width: 66px;
  height: 42px;
  position: relative;
  top: 14px;
  margin: 0 10px;
}

// WP Forms
div.wpforms-container-full .wpforms-form .wpforms-field-label { font-size: 14px; }
div.wpforms-container-full .wpforms-form button[type=submit] {
  background-color: $yellow;
  border: 1px solid $yellow;
  color: #fff;
}

/* Small devices (tablets, 768px and up) */
@media (min-width: $screen-sm-min) {}

/* Medium devices (desktops, 992px and up) */
@media (min-width: $screen-md-min) {}

/* Large devices (large desktops, 1200px and up) */
@media (min-width: $screen-lg-min) {
  .header-jumbotron .mad {
    width: 85px;
    height: 55px;
  }
}