h1, h2, h3, h4, h5, h6 {
  font-family: 'Raleway', Sans-serif;
  color: #222;
}

h1 { font-size: 26px; }
h2 { font-size: 24px; }
h3 { font-size: 22px; }
h4 { font-size: 20px; }
h5 { font-size: 18px; }
h6 { font-size: 16px; }

p {
  line-height: 1.7em;
}

a {
  //color: $pink;
}

.page-header {
  border: none;
  margin: 0 0 20px;
  padding: 0;

  h1 {
    margin: 0;
  }
}